import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { switchMap } from 'rxjs/operators'
import { Observable } from 'rxjs';
import { Project } from "../../../data/project";

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {

  //projects: Project[];

  constructor(
    ) {}


  ngOnInit() {
  }

}
