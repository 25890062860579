import { Component, OnInit, Input } from "@angular/core";
import { Project } from "../../../data/project";
import { ProjectService } from '../../../services/project.service';

import { switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"]
})
export class ProjectComponent implements OnInit {

  projects$: Observable<Project[]>;
  
  public liveIntent: Object;
  public parkingDuck: Object;
  public keylime: Object;
  public waterfall: Object;
  private anim: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ProjectService
  ) {
    this.liveIntent = {
      path: './../../assets/PortfolioResources/LiveIntent/LiveIntent.json',
      autoplay: true,
      loop: true
     };

     this.parkingDuck = {
       path:'./../../assets/PortfolioResources/parkingduck/parkingduck2.json',
       autoplay: true,
       loop: true
     };

     this.keylime = {
      path:'./../../assets/PortfolioResources/Keylime/keylime.json',
      autoplay: true,
      loop: true
    };

    this.waterfall = {
      path:'./../../assets/PortfolioResources/graphicsWaterfall/waterfall.json',
      autoplay: true,
      loop: true
    };

  }
  handleAnimation(anim: any) {
    this.anim = anim;
}

  play() {
    this.anim.play();
}

pause() {
    this.anim.pause();
}

  ngOnInit() {
    this.projects$ = this.route.paramMap.pipe(
      switchMap(
        params => {
          return this.service.getProjects();
        })
    );
    console.log(this.projects$);
    

  }
}
