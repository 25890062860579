import { Component, OnInit } from '@angular/core';
import Typed from 'typed.js';

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss']
})
export class HeroBannerComponent implements OnInit {
 
  constructor() {
   }

  ngOnInit() {
    const options = {
      strings: ['whoami <br> ^1000\n > Hi, I am Amy. ^1000\n <br> > I am a <span style="color:#f9aa33;"> Product Designer</span> with a <span style="color:#f9aa33;"> Technical background</span>. ^1000\n<br> > My goal is to <span style="color:#f9aa33;">track, build, and understand </span> purposeful interactions.`<br> <br>~ $'],
      typeSpeed: 5,
      backDelay: 25,
      startDelay: 0,
      showCursor: true,
      smartBackspace: true,
      curseChar: '|',
      autoInsertCss: true,
    };

    const typed = new Typed('.typed-element', options);
  }

}
