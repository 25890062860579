import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HeroBannerComponent } from './Components/hero-banner/hero-banner.component';
import { ProjectPageComponent } from './project-page/project-page.component';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { AppComponent } from './app.component';
import { ProjectListComponent } from './Components/project-list/project-list.component';
import { GameComponent } from './Game/game/game.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  //on change of router url the position of the screen will set to th top.
  anchorScrolling: 'enabled',
  //When enabled, scrolls to the anchor when the URL has fragment (disable=default)
  scrollOffset: [0,64],
  //Configures the scroll offset the router will use when scrolling to an element.
};

export const routes: Routes = [
  { path: '', redirectTo: 'content', pathMatch: 'full' },  
  { path: 'project/:id', component: ProjectPageComponent},
  { path: 'aboutme', component: AboutmeComponent },
  { path: 'content', component: HeroBannerComponent},
  { path: 'projects', component: ProjectListComponent},
  { path: 'playground', component: GameComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
