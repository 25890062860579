import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Projects } from '../data/SelectedProjects';
import { Project } from '../data/project';
import { MessageService } from './message.service';
import { TeamModel } from '../data/team-model';
import { TeamMembers } from '../data/TeamMembers';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor( private messageService: MessageService) { }

  getProjects(): Observable<Project[]> {
    this.messageService.add('Say something to Amy');
    return of(Projects);
  }

  getProject(id: number | string){
    return this.getProjects().pipe(
      map((projects: Project[] )=> projects.find(project => project.id === id))
    )
  }

  getAllMembers(): Observable<TeamModel[]> {
    this.messageService.add("This is All Members");
    return of(TeamMembers);
  }
}
