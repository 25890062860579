import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule, MatIcon} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import { NgModule } from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';


@NgModule({
    imports: [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatChipsModule, MatCardModule, MatGridListModule, MatProgressBarModule],
    exports: [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatChipsModule, MatCardModule, MatGridListModule, MatProgressBarModule],
})

export class MaterialModule { }