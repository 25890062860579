import { Project } from "./project";

export const Projects: Project[] = [
  {
    id: "1",
    image: "./../../assets/PortfolioResources/LiveIntent/LiveIntent_new.png",
    title: "Redesigning the LiveConnect Tag",
    subtitle: "UI/UX Designer @ LiveIntent (Berlin/Remote) - 2018",
    description:
      "A LiveConnect Tag is a website tracking tag that allows our clients to track their users' behavior such as product purchase, add to cart, email signup, etc. With the LiveConnect Tag, we have accomplished over 63 billion impressions/month and reached over 359 million people. ",
    tags: [
      "Ad Tech",
      "Marketing Tech",
      "Retargeting Audience",
      "Identity Graph",
      "B2B"
    ],
    responsibility_tags: []
  },
  {
    id: "5",
    image: "./../../assets/PortfolioResources/dataviz/dataviz.png",
    title: "Data Visualization for Private Customer Graph",
    subtitle: "UI/UX Designer @ LiveIntent (Berlin/Remote) - 2019",
    description:
      "This Data Dashboard was created as an Extension of the LiveConnect Project. The project aims to help 2000+ brands reach over 170 million people across 4.3 billion connections in our Identity Graph and identify unique hashes from those connections.",
    tags: [
      "Data Visualization",
      "Private Customer Graph",
      "Identity Graph",
      "Dashboard",
    ],
    responsibility_tags: []
  },
  {
    id: "2",
    image: "./../../assets/PortfolioResources/parkingduck/parkingduck-banner.png",
    title: "Parking Duck - The Parking App",
    subtitle: "CCO (UX Lead) @ ParkingDuck (Bangkok, Thailand) - 2015",
    description:
      "We want to solve the infamous traffic congestion problem in Bangkok, Thailand. ParkingDuck is a P2P solution to connect people with unused parking spaces in order to reduce the number to active cars during rush hours.",
    tags: ["Ruby On Rails", "Parking Engine", "Peer-2-Peer"],
    responsibility_tags: []
  },
  {
    id: "3",
    image: "./../../assets/PortfolioResources/Keylime/Keylime.png",
    title: "Keylime - Computer Security Project",
    subtitle: "UI/UX Lead Developer @ Keylime by RedHat (Remote) - 2019",
    description:
      "Keylime is an open source, TPM based, highly scalable remote boot attestation and runtime integrity measurement solution. Keylime enables cloud users to monitor remote nodes using a hardware based cryptographic root of trust. It was originally born out of the security research team in MIT's Lincoln Laboratory and later incubated by RedHat.",
    tags: [
      "Open Source",
      "TPM",
      "Security",
      "Cryptography",
      "Angular",
      "Python"
    ],
    responsibility_tags: []
  },
  {
    id: "4",
    image: "./../../assets/PortfolioResources/graphicsWaterfall/Graphics waterfall.png",
    title: "Graphics Waterfall - Hardware Prototype",
    subtitle: "Prototyper @Mahidol University - 2013",
    description:
      "The Graphics Waterfall Project was created as a working prototype to mimic the original waterfall system in Osaka Station, Japan. We used a raspberryPi as a controller and WiringPi2 to control GPIO ports. The communication protocol was done in I2C and process image files with matplotlib to print water droplets in visual forms.",
    tags: [
      "RaspberryPi",
      "Image Processing",
      "WiringPi2",
      "matplotlib",
      "Python"
    ],
    responsibility_tags: []
  }
];
