export class Feature {
    constructor(
        public header: string,
        public year: string,
        public link: string,
        public category: string,
        public description: string,
        public avatar: string,
        public avatarName: string,
        public image: string,
        public stats: string
    ){}
}
