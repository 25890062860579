import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';  


@Component({
  selector: "app-live-connect-details",
  templateUrl: "./live-connect-details.component.html",
  styleUrls: ["./live-connect-details.component.scss"],
  providers: [NgbCarouselConfig] ,
})
export class LiveConnectDetailsComponent implements OnInit {
  public lottieLC_1: Object;
  private anim: any;

  data = [
    {
      image: 'https://drive.google.com/uc?export=download&id=1qswPmkY2tdye-2xYSOJ4uODO__NxMx-U',
      text: 'Overall view of all Event Tags that we received. This allows our users to troubleshoot events that our server collected.'
    },
    {
      image: 'https://drive.google.com/uc?export=download&id=14rcYEoXowlYIRLXExLOCeZsendcAOzwL',
      text: 'Customizable column that saves the last state and error view. This allows Sales Engineers, Customer Success Operation Managers, and Sales Team Members to customize their views according to their needs. '
    },
    {
      image: 'https://drive.google.com/uc?export=download&id=17Ax8YqEk7Vxt3ZJrzwjes50NxmSXUEfn',
      text: 'Drill down to individual Event activities. Certain events might receive errors and we what to help them find out where the error is.'
    },
    {
      image: 'https://drive.google.com/uc?export=download&id=1por-Dv68KOJaiPk0FDipNrCsEnjwsljr',
      text: 'Setting up instructions for LiveConnect Tag creation. We want to help our users keep track of the events that has been created VS the correct script.'
    },
    {
     image: 'https://drive.google.com/uc?export=download&id=1XIm7y0n1IeF_Xs6JCUl6yXutW9Ffri95',
     text: 'Event Tag information and Next Steps. The script will be customized based on the settings made on the checkboxes.' 
    }
  ]


  constructor() {
    this.lottieLC_1 = {
      path: "./../../assets/PortfolioResources/LiveIntent/images/LC_1.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }
  

  handleAnimation(anim: any) {
    this.anim = anim;
  }
  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }

  ngOnInit() {}
}
