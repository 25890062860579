import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-viz',
  templateUrl: './data-viz.component.html',
  styleUrls: ['./data-viz.component.scss']
})
export class DataVizComponent implements OnInit {

  imageData = [
    {
      imageFile: '../../assets/PortfolioResources/dataviz/Details - SVR.png',
      fileName: 'SVR_1',
      caption: 'This is an example of an SVR file type delivery, which will output the count of total hashes (MD5, SHA1, SHA256)',
      features: 'Interactive Bar Graph',
      featureDesc:'The Bar Graph Component will populate on different dates. When a bar graph is clicked, the pie chart data will be different from one day to another.',
      why:'This interaction helped our users see an overall data as well as the drill down version of the data.',
      likes: '8',
      totalTester: '8'
    },
    {
      imageFile: '../../assets/PortfolioResources/dataviz/Details - SVR - 2.png',
      fileName: 'SVR-2',
      caption: 'This is the to show that the Bar Graph is interactive and will out put a different result on the bottom component (Pie Chart).',
      features: 'Subset Pie Chart',
      featureDesc:'The small pie chart aims to provide the idea of match hashes that our engine can identify. Each color has a hover interaction that you can identify what sources they are from.',
      why:'This graph aims to simplify the meaning of our numbers.',
      likes: '8',
      totalTester: '8'
    },
    {
      imageFile: '../../assets/PortfolioResources/dataviz/Details - cluster.png',
      fileName: 'cluster',
      caption: 'This is an example of how we provide data on a cluster delivery format.',
      features: 'Header with Summary',
      featureDesc:'This header component allows our users to search for their reporting files, see the important information such as the status, cadence, graph type, link to the file, and the overall configuration.',
      why:'This is to help our users confirm and debug errors when they occur from a setting.',
      likes: '8',
      totalTester: '8'
    }
  ]

  imagePreTest = [
    {
      imageFile: '../../assets/PortfolioResources/dataviz/Details (1).png',
      fileName: 'pretest1',
      caption: 'A simple view of all data we can pull VS the data we can identify.'
    },
    {
      imageFile: '../../assets/PortfolioResources/dataviz/Details.png',
      fileName: 'pretest2',
      caption: 'Second version of the view to show how different components were placed VS how our users understand them.'
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
