import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//extra Modules
import { GistModule } from '@sgbj/angular-gist';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from './material';
import { ParkingDuckComponent } from './parking-duck/parking-duck.component';
import { KeyLimeComponent } from './key-lime/key-lime.component';
import { MatVideoModule } from 'mat-video';

//Created in the Project
import { HeroBannerComponent } from './Components/hero-banner/hero-banner.component';
import { ProjectListComponent } from './Components/project-list/project-list.component';
import { ProjectComponent } from './Components/project/project.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { QuoteBlockComponent } from './Components/quote-block/quote-block.component';
import { FeaturedComponent } from './Components/featured/featured.component';
import { ProjectPageComponent } from './project-page/project-page.component';
import { LiveConnectDetailsComponent } from './live-connect-details/live-connect-details.component';
import { WaterFallDetailsComponent } from './water-fall-details/water-fall-details.component';
import { FooterComponentComponent } from './Components/footer-component/footer-component.component';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { DataVizComponent } from './data-viz/data-viz.component';
import { EngineComponent } from './Game/engine/engine.component';
import { GameComponent } from './Game/game/game.component';



@NgModule({
  declarations: [
    AppComponent,
    HeroBannerComponent,
    ProjectListComponent,
    ProjectComponent,
    QuoteBlockComponent,
    FeaturedComponent,
    ProjectPageComponent,
    LiveConnectDetailsComponent,
    WaterFallDetailsComponent,
    ParkingDuckComponent,
    KeyLimeComponent,
    FooterComponentComponent,
    AboutmeComponent,
    DataVizComponent,
    EngineComponent,
    GameComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LottieAnimationViewModule.forRoot(),
    GistModule,
    NgbCarouselModule,
    MatVideoModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
