import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { Project } from '../../data/project';
import { ProjectService } from '../../services/project.service';
import { Router, ActivatedRoute,ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TeamModel } from '../../data/team-model';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss']
})
export class ProjectPageComponent implements OnInit {

  project$: Observable<Project>
  projects$: Observable<Project[]>;
  TeamModel$: Observable<TeamModel[]>
  scrollTop: number;
  scrollHeight: number;
  elementHeight: number;
  bottom: number;
  percent: number;
  height: number;

  constructor( 
    private router: Router, 
    private route: ActivatedRoute,
    private service: ProjectService,
    private _ruler: ViewportRuler,
    @Inject(DOCUMENT) private document: Document) {
   }

  ngOnInit() {
    this.project$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.service.getProject(params.get('id')))
    );

    this.projects$ = this.route.paramMap.pipe(
      switchMap(
        params => {
          return this.service.getProjects();
        })
    );

    this.TeamModel$ = this.route.paramMap.pipe(
      switchMap(
        params => {
          return this.service.getAllMembers();
        })
    );
    console.log("this is model");
    console.log("View port size",this._ruler.getViewportSize(),this._ruler.getViewportSize().height);
    console.log("View port scroll",this._ruler.getViewportScrollPosition());
    console.log("view port rect", this._ruler.getViewportRect().top);
  }


  @HostListener("window:scroll", ['$event'])
  onWindowScroll($event) {

    this.scrollTop = this._ruler.getViewportScrollPosition().top;
    this.elementHeight = this._ruler.getViewportSize().height;
    this.scrollHeight = this.document.documentElement.scrollHeight;
    this.bottom = this._ruler.getViewportRect().bottom;
    this.height = this.scrollHeight - this.elementHeight;
    this.percent = (this.bottom/this.height)*100;
    
  }

}
