import {
  Injectable,
  Component,
  ElementRef,
  NgZone,
  AfterViewInit,
  ViewChild,
  Input
} from "@angular/core";
import * as THREE from "three";

@Component({
  selector: "app-engine",
  templateUrl: "./engine.component.html",
  styleUrls: ["./engine.component.scss"]
})
export class EngineComponent implements AfterViewInit {
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private cameraTarget: THREE.Vector3;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  @ViewChild("canvas", { static: false })
  private canvasRef: ElementRef;

  // stage properties

  // @Input()
  public cameraZ: number = 400;

  @Input()
  public fieldOfView: number = 70;

  @Input("nearClipping")
  public nearClippingPane: number = 1;

  @Input("farClipping")
  public farClippingPane: number = 1000;

  //panorama properties
  private material: THREE.MeshBasicMaterial;
  public widthSegments: number = 300;
  public heightSegments: number = 300;
  public radius: number = 500;

    /* USER INTERACTION PROPERTIES */

    private isUserInteracting: boolean = false;

    private latitude: number = 0;
  
    private longitude: number = 0;
  
    private onPointerDownPointerX: number = 0;
  
    private onPointerDownPointerY: number = 0;
  
    private onPointerDownLongitude: number = 0;
    
    private onPointerDownLatitude: number = 0;
  
    private phi: number = 0;
  
    private theta: number = 0;


  // Texture properties
  // public texture: string = 'https://github.com/LayZeeDK/ng-three-examples/blob/master/src/assets/textures/crate.gif?raw=true';
  public texture_earth: string = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/141228/earthmap1k.jpg';
  public bumpmap: string = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/141228/earthbump1k.jpg';
  public specularmap: string = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/141228/earthspec1k.jpg';
  public smoke: string = 'https://redstapler.co/wp-content/uploads/2019/05/smoke-1.png';

  // Default rotation speed
  public rotationSpeedX: number = 0.005;
  public rotationSpeedY: number = 0.01;

  public constructor() {}

  /**
   * Update scene after resizing.
   */
  public onResize(event: Event) {
    this.camera.aspect = this.getAspectRatio();
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
  }

  // create Scene

  private createScene() {
    this.scene = new THREE.Scene();
  }

  private createCamera() {
    let aspecRatio = this.getAspectRatio();
    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspecRatio,
      this.nearClippingPane,
      this.farClippingPane
    );
    this.camera.position.z = this.cameraZ;
    this.cameraTarget = new THREE.Vector3(0.25,1,2);
  }

  private getAspectRatio() {
  
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }

  //panorama
  private createPanorama() {
    let geometry = new THREE.SphereGeometry(
      this.radius,
      this.widthSegments,
      this.heightSegments
    );
    geometry.scale(2, 3, 1);

    let map = new THREE.TextureLoader()
      .load('https://s3-us-west-2.amazonaws.com/s.cdpn.io/141228/starfield.png');
    this.material = new THREE.MeshBasicMaterial({ map: map, side: THREE.BackSide });
    let mesh = new THREE.Mesh(geometry, this.material);
    this.scene.add(mesh);
  }

  private rotateCamera() {
    if (this.isUserInteracting === false) {
      this.longitude += 0.1;
    }

    this.latitude = Math.max(-85, Math.min(85, this.latitude));
    this.phi = THREE.Math.degToRad(90 - this.latitude);
    this.theta = THREE.Math.degToRad(this.longitude);

    this.cameraTarget.x = 500 * Math.sin(this.phi) * Math.cos(this.theta) - 300;
    this.cameraTarget.y = 500 * Math.cos(this.phi);
    this.cameraTarget.z = 500 * Math.sin(this.phi) * Math.sin(this.theta);

    this.camera.lookAt(this.cameraTarget);
  }


  // sphere
  private geometry: THREE.SphereBufferGeometry;
  private sphere: THREE.Mesh;
  private galaxy: THREE.Mesh;
  private cloud: THREE.Mesh;

  private createSphere() {
    this.geometry = new THREE.SphereBufferGeometry(90, 32, 32);
    let texture = new THREE.TextureLoader().load(this.texture_earth);
    let bumpmap = new THREE.TextureLoader().load(this.bumpmap);
    let specularmap = new THREE.TextureLoader().load(this.specularmap);
    let material = new THREE.MeshPhongMaterial({
      map: texture,
      bumpMap: bumpmap,
      bumpScale: 0.05,
      opacity: 0.8,
      specularMap: specularmap,
      specular: new THREE.Color('grey'),
      transparent: true,
      depthWrite: false
    });
    this.sphere = new THREE.Mesh(this.geometry,material);
    this.sphere.receiveShadow = true;
    this.sphere.castShadow =true;
    this.scene.add(this.sphere);
  }

  private createCloud() {
    const cloudGeometry = new THREE.SphereGeometry(0.503,32,32);
    const texture = new THREE.TextureLoader().load('https://github.com/turban/webgl-earth/blob/master/images/fair_clouds_4k.png?raw=true');
    let material = new THREE.MeshPhongMaterial({
      map:texture,
      transparent:true
    });
    this.cloud = new THREE.Mesh(cloudGeometry,material);
    this.scene.add(this.cloud);
  }

  private addGalaxy() {
    let galaxyGeometry = new THREE.SphereGeometry(140 , 90, 90);
    let galaxyTexture = new THREE.TextureLoader().load('https://s3-us-west-2.amazonaws.com/s.cdpn.io/141228/starfield.png');
    let galaxyMaterial = new THREE.MeshBasicMaterial({ map:galaxyTexture, side:THREE.BackSide});
    this.galaxy = new THREE.Mesh(galaxyGeometry,galaxyMaterial);
    this.scene.add(this.galaxy);
  }

  private particleSystem: THREE.Points;
  private particles: THREE.CircleGeometry;

  private addStar() {
    this.particles = new THREE.CircleGeometry(0.1,20);
    for(var p = 0; p< 1000; p++) {
      const particle = new THREE.Vector3(
        Math.random()*500 - 250, Math.random()*500 - 250, Math.random()*1000-500
      );
      this.particles.vertices.push(particle);
    }
    const particleMaterial = new THREE.PointsMaterial({
      color: 0xffffff, 
      size: 0.7,
    });
    this.particleSystem = new THREE.Points(this.particles, particleMaterial);
    this.scene.add(this.particleSystem);
  }

  private animateStar() {    
    for( let k =0; k < 1000; k++){
      this.particleSystem.rotation.x += 0.000001;
      this.particleSystem.rotation.y += 0.000001;
    }
  }


  //  /**
  //  * Animate the sphere
  //  */
  private animateSphere() {
    this.sphere.rotation.y += this.rotationSpeedY;
    this.cloud.rotation.y += this.rotationSpeedY;
  }

  /**
   * Start the rendering loop
   */

  private addLights() {
      //illuminate all lights from all scenes
      this.scene.add(new THREE.AmbientLight(0x555555));
      //light in the sky
      // var light = new THREE.DirectionalLight(0xffffff, 1);
      var light = new THREE.DirectionalLight(0xffeedd);
      light.position.set(2,3,1);
      this.scene.add(light);
  }

  private createFlash() {
    const flash = new THREE.PointLight(0x062d89, 30, 500 ,1.7);
    flash.position.set(200,300,100);
    this.scene.add(flash);
  }

  private startRenderingLoop() {
    /* Renderer */
    // Use canvas element in template
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    let component: EngineComponent = this;
    (function render() {
      requestAnimationFrame(render);
      component.animateStar();
      component.animateSphere();
      component.rotateCamera();
      component.renderer.render(component.scene, component.camera);
    }());
  }

   /* EVENTS */

   public onDragEnter(event: DragEvent) {
    this.canvas.style.opacity = 0.5.toString();
  }

  public onDragLeave(event: DragEvent) {
    this.canvas.style.opacity = 1.0.toString();
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();

    let component: EngineComponent = this;
    let reader = new FileReader();
    reader.addEventListener('load', function onDroppedFileLoad() {
      component.material.map.image.src = reader.result;
      component.material.map.needsUpdate = true;
    });
    reader.readAsDataURL(event.dataTransfer.files[0]);

    this.canvas.style.opacity = 1.0.toString();
  }  

  public onMouseDown(event: MouseEvent) {
    event.preventDefault();

    this.isUserInteracting = true;
    this.onPointerDownPointerX = event.clientX;
    this.onPointerDownPointerY = event.clientY;
    this.onPointerDownLatitude = this.latitude;
    this.onPointerDownLongitude = this.longitude;
  }

  public onMouseMove(event: MouseEvent) {
    if (this.isUserInteracting !== true) {
      // Propagate event
      return true;
    }

    this.latitude = (event.clientY - this.onPointerDownPointerY) * 0.1 +
      this.onPointerDownLatitude;
    this.longitude = (this.onPointerDownPointerX - event.clientX) * 0.1 +
      this.onPointerDownLongitude;
  }

  public onMouseUp(event: MouseEvent) {
    this.isUserInteracting = false;
  }

  public onWheel(event: MouseWheelEvent) {
    this.camera.fov += event.deltaY * 0.05;
    this.camera.updateProjectionMatrix();
  }

  ngAfterViewInit() {
    this.createScene();
    this.createCamera();
    this.createPanorama();
    this.addGalaxy();
    this.addLights();
    this.addStar();
    this.createSphere();
    this.createFlash();
    this.createCloud();
    this.startRenderingLoop();
  }
}
