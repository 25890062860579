import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-parking-duck',
  templateUrl: './parking-duck.component.html',
  styleUrls: ['./parking-duck.component.scss'],
  providers: [NgbCarouselConfig] 
})
export class ParkingDuckComponent implements OnInit {

  data = [
    {
      image: 'https://drive.google.com/uc?export=download&id=1NB8U6NvcLlvQh7IFtIi7BZuYXjGpaZUU',
      text: 'The Loading Page contains our logo and a blurry background of the city.',
      header: 'Loading Page'
    },
    {
      image: 'https://drive.google.com/uc?export=download&id=1ZbfgadOzyhkQJ2zRze5tUGwDe_V1yZYK',
      text: 'Log in with Facebook is one of the most preferred option for logging and signing up in Thailand. Therefore, this is almost a default option for loggin in.',
      header: 'Log in Page'
    },
    {
      image: 'https://drive.google.com/uc?export=download&id=1bKyGe1ogv4iuaULr79OHNij-TvEAx1FA',
      text: 'Date selection is very important for a country that accepts different data formats. In the final design, the important part is to provide a solid month and emphasize the day to avoid confusion. Also it is very important to provide the parking duration to avoid misunderstanding of parking duration.',
      header: 'Date Selection'
    },
    {
     image: 'https://drive.google.com/uc?export=download&id=1gEwnzaCotO3xZdRyf6Lqpbu-MSMwtoJ0',
     text: 'In this prototype, we worked with an enterpise business model to provide multiple listings and allow our enterprise users to calibrate their prices and parking slot through different time frames.',
     header: 'Listings' 
    },
    {
     image: 'https://drive.google.com/uc?export=download&id=1OiA8VNu1PPRw03p7vcT1B588xAC-lEQl',
     text: 'Because most users registered with a facebook account, we need to ask for the license plate later for a reservation. This information will be kept in our database after the first input and they can add or edit a different license plate later. Our payment method will always be through credit card',
     header: 'Input form' 
    },
    {
     image: 'https://drive.google.com/uc?export=download&id=1Z2-PkIscFrSYSSTEcmEpjn3ORcmz22iN',
     text: 'Our confirmation page hilights all important information that a renter needs to present to the parking space owner. Likewise, our parking owners will also be able to confirm these informaiton on-site.',
     header: 'Confirmation' 
    }
  ]

  constructor(config: NgbCarouselConfig) {
    config.interval = 5000;
   }

  ngOnInit() {
  }

}
