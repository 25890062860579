import { TeamModel } from './team-model';

export const TeamMembers: TeamModel[] = [
    {
        teamName: "Karsten (Berlin)",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/steve.jpg?raw=true",
        teamRole: "Director, Product Manager",
        projectId: "1"
     },
    {
       teamName: "Amy (Berlin)",
       teamImage: "../../assets/PortfolioResources/Amy.png",
       teamRole: "UX/UI Designer - Concepts and Interactions",
       projectId: "1"
    },
     {
        teamName: "Ari (New York)",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/matt.jpg?raw=true",
        teamRole: "Software Engineer",
        projectId: "1"
     },
     {
        teamName: "Chris (New York)",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/matthew.png?raw=true",
        teamRole: "Software Engineer",
        projectId: "1"
     },
     {
        teamName: "Samantha (New York)",
        teamImage:"https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/nan.jpg?raw=true",
        teamRole: "Software Engineer",
        projectId: "1"
     },
     {
        teamName: "Paruey Anadirekkul",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/chris.jpg?raw=true",
        teamRole: "Chief Executive Officer and Co-Founder",
        projectId: "2"
     },
     {
        teamName: "Chayanon Visutthithada",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/christian.jpg?raw=true",
        teamRole: "Chief Operating Officer and Co-Founder",
        projectId: "2"
     },
     {
        teamName: "Pawit Khid-arn",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/daniel.jpg?raw=true",
        teamRole: "Chief Technology Officer and Co-Founder",
        projectId: "2"
     },
     {
        teamName: "Jirayu Limjinda",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/elliot.jpg?raw=true",
        teamRole: "Chief Product Officer and Co-Founder",
        projectId: "2"
     },
     {
        teamName: "Amy Pattanasethanon",
        teamImage: "../../assets/PortfolioResources/Amy.png",
        teamRole: "Chief Creative Officer (UX Lead)",
        projectId: "2"
     },
     {
        teamName: "Luke Hinds (RedHat, UK)",
        teamImage:"https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/mark.png?raw=true",
        teamRole: "Senior Principle Software Engineer (CTO office)",
        projectId: "3"
     },
     {
        teamName: "Andrew Toth (RedHat, USA)",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/matthew.png?raw=true",
        teamRole: "Associate Manager NFV Partner Engineering",
        projectId: "3"
     },
     {
        teamName: "Nabil Schear (MIT Lincoln Lab, USA)",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/christian.jpg?raw=true",
        teamRole: "Security Engineer",
        projectId: "3"
     },
     {
        teamName: "Charlie Munson (Community Contributor)",
        teamImage:"https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/daniel.jpg?raw=true",
        teamRole: "Keylime Core Team/ Global Aircraft",
        projectId: "3"
     },
     {
        teamName: "Amy (Community Contributor)",
        teamImage: "../../assets/PortfolioResources/Amy.png",
        teamRole: "UX/UI Lead Developer - Design + Develop",
        projectId: "3"
     },
     {
        teamName: "Community Contributors",
        teamImage:"https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/elliot.jpg?raw=true",
        teamRole: "10+ Other Community Contributors",
        projectId: "3"
     },
     {
        teamName: "Dr. Arnan (Roger) Sipitakiat",
        teamImage:"https://media.licdn.com/dms/image/C4E03AQFoh4Y5FAflYg/profile-displayphoto-shrink_200_200/0?e=1580342400&v=beta&t=r57icNDCL2PXbYDN1OSKaqU4vknvoxxqnjVw_1hs2xU",
        teamRole: "Project Advisor (Co-Inventer of GoGoBoard, MIT Media Lab)",
        projectId: "4"
     },
     {
        teamName: "Asst. Prof. Thanadol Pritranan",
        teamImage:"https://graduate.mahidol.ac.th/assets61/img/pic_staff/391.jpg",
        teamRole: "Project Advisor (Com Eng), Mahidol University",
        projectId: "4"
     },
     {
        teamName: "Asst. Prof. Dr.Ittichote Chuckpaiwong",
        teamImage:"https://i1.rgstatic.net/ii/profile.image/408796548616196-1474476100938_Q512/Ittichote_Chuckpaiwong.jpg",
        teamRole: "Project Advisor (Mech Eng), Mahidol University",
        projectId: "4"
     },
     {
        teamName: "Deeprom Somkiadchareon",
        teamImage:"https://media.licdn.com/dms/image/C5103AQG8FBDvtXVIVA/profile-displayphoto-shrink_200_200/0?e=1580342400&v=beta&t=3qg0T2CNEC_4s7YQB4mbyntWyGtib0ZxTdGsvGFz_6U",
        teamRole: "Circuit Design, Hardware Design, and Prototyper",
        projectId: "4"
     },
     {
        teamName: "Amy Bhinyaporn Pattanasethanon",
        teamImage: "https://media.licdn.com/dms/image/C5603AQEPeIBDwsH7OA/profile-displayphoto-shrink_200_200/0?e=1580342400&v=beta&t=IPHoWIHuFUvAljq5I8OBrT24wcdGnq1B6nlotWp3aPs",
        teamRole: "Image Processing, Circuit Design, and Prototyper",
        projectId: "4"
     },
     {
        teamName: "Kamonnate Cheevaprawatdomrong",
        teamImage: "https://scontent-lhr3-1.xx.fbcdn.net/v/t1.0-1/p320x320/74157349_10156911765112753_3489519314338316288_n.jpg?_nc_cat=108&_nc_ohc=Cg3_i1jvg4EAQnYB3Y-cHMJm1yYq1AVxnvpo4OUbJmUbsb-QUA4V9pdLQ&_nc_ht=scontent-lhr3-1.xx&oh=31560b3305c8b45f906075dd398d997d&oe=5E8147A5",
        teamRole: "UI Design and Prototyper",
        projectId: "4"
     },
     {
        teamName: "Gareth (Berlin)",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/daniel.jpg?raw=true",
        teamRole: "Director, Product Manager",
        projectId: "5"
     },
     {
        teamName: "Amy (Berlin)",
        teamImage: "../../assets/PortfolioResources/Amy.png",
        teamRole: "UX/UI Designer - Concepts and Interactions",
        projectId: "5"
     },
     {
        teamName: "Volodia (Berlin)",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/matthew.png?raw=true",
        teamRole: "Software Engineer",
        projectId: "5"
     },
     {
        teamName: "Jan (Berlin)",
        teamImage: "https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/public/images/avatar/small/matt.jpg?raw=true",
        teamRole: "Software Engineer",
        projectId: "5"
     },
     






]