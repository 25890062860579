import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-key-lime',
  templateUrl: './key-lime.component.html',
  styleUrls: ['./key-lime.component.scss']
})
export class KeyLimeComponent implements OnInit {
  URL = ' '; 
  constructor() { }

  ngOnInit() {
  }

  openNewWindow(URL) {
    window.open(URL, "_blank");
  }

}
