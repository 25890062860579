import { Component, OnInit } from '@angular/core';
import { Feature } from '../featured/feature'
@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss']
})
export class FeaturedComponent implements OnInit {

  features: Feature[];

  constructor() {
    this.features = [
      new Feature(
        'TechAcute',
        '2018',
        'https://techacute.com/what-does-a-ux-ui-designer-do/',
        'Interview',
        'Early 2018, I had the privilege be invited for an interview with Christopher Isak about the rolls of a UX/UI designer and what challenges we might have in order to scale and sustain our designs, while keeping our users happy. During this interview, we emphasized the importance of Empathy in terms of understanding our users like we are going through the struggles with them.',
        'https://github.com/Semantic-Org/Semantic-UI/blob/master/examples/assets/images/avatar/tom.jpg?raw=true',
        'Christopher Isak',
        'http://wikid.io.tudelft.nl/WikID/images/a/ad/SUID1.gif',
        ''
      ),
      new Feature(
        'Image Processing',
        '2013',
        'https://medium.com/@amylilycarnes/new-to-image-processing-5aa5200aa895?source=friends_link&sk=52294b4ce9d890b0dee6c9dda08518ef',
        'Tutorial',
        'Image Processing is one of my favorite classes during my senior year. This is a very simple tutorial for beginners to try out different techniques such as RGB extraction, Object Labeling, and Filtering. I hope this tutorial would be a good guidance for people who would like to try out simple functions and understand the concepts of processing data in multi-dimensional arrays.',
        'https://miro.medium.com/fit/c/192/192/0*M_theoIN6bL7iQTI.',
        'Amy',
        'https://raynecarnes.files.wordpress.com/2014/02/screen-shot-2014-02-24-at-6-00-16-pm.png',
        '412 views'
      ),
      new Feature(
        'GoGo Board',
        '2015-2017',
        'https://gogoboard.org/about/',
        'UX Researcher',
        'GoGo Board is an instrumental circuit board kit with basic programming instructions for children to build their own robotic tools. My task includes research on the GoGo Board compiler UX, learning materials, and designed gogoboard.org v.2 UI to help children understand the concept of block programming. Being fortunate enough to follow my professor\'\s founding venture was very rewarding and helped me understand how children\'\s minds work.',
        'https://i1.rgstatic.net/ii/profile.image/273709286031361-1442268787183_Q512/Arnan_Sipitakiat.jpg',
        'Professor Roger Sipitakiat',
        'https://raynecarnes.files.wordpress.com/2014/02/logogogo.jpg',
        ''
      ),
      new Feature(
        'Setting Up Gulp and BrowserSync',
        '2019',
        'https://medium.com/swlh/setting-up-gulp-4-0-2-for-bootstrap-sass-and-browsersync-7917f5f5d2c5?source=friends_link&sk=8d09a3d7b62fa11a35dd0b5156b6ab73',
        'Tutorial',
        'Setting up Gulp 4 using BrowserSync to automate changes on the browser is one of my article with 4K+ views. I am very happy to see that other people are benefiting from this article and that it helped them solve their problems.',
        'https://miro.medium.com/fit/c/96/96/0*M_theoIN6bL7iQTI.',
        'Amy',
        'https://miro.medium.com/max/1283/1*cKouMGEdmIrJ45zqWNGMCA.png',
        '4.97K Views'
      ),
      new Feature(
        'Remote Ideation',
        '2019',
        'https://medium.com/swlh/making-the-most-out-of-remote-ideation-495a40e615ef?source=friends_link&sk=0bf74c985b409d6d1a148e3e8d771197',
        'Learning',
        'When your company has more than one offices around the world, dealing with different timezones and teams can be difficult. These are some of my learnings in the past 3 years of how we can make the most out of remote ideation.',
        'https://miro.medium.com/fit/c/96/96/0*M_theoIN6bL7iQTI.',
        'Amy',
        'https://miro.medium.com/max/590/1*dODv3JEfamPes04RKpav8Q.png',
        '120 Views'
      ),
      new Feature(
        'Teaching Design Thinking',
        '2015',
        'https://www.grab.com/th/en/press/others/english-grabtaxi-launches-grabschool-to-teach-drivers-children-entrepreneurship-and-life-skills/',
        'Mentorship',
        'GrabTaxi was one of the partnership program with Asian Leadership Academy(ALA). I was working with ALA for 5 years to bring Design Thinking to Thailand.',
        'https://www.grab.com/th/wp-content/themes/grabsg/images/favicon.ico',
        'Grab',
        'http://www.grab.com/th/wp-content/uploads/sites/6/2016/01/children1.jpg',
        '56 Attendees'
      )


    ]
   }

  ngOnInit() {
  }

}
