import { Component } from '@angular/core';
import Typed from 'typed.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Amy Lily';

  constructor(private router: Router) {

  }

  ngOnInit() {
  }

  aboutMe() {
    this.router.navigate(['home']);
  }
  
  openLinkedIn() {
    window.open("https://www.linkedin.com/in/amylily/", "_blank");
  }

  openGitHub() {
    window.open("https://github.com/amylily1011","_blank");
  }

  openBehance() {
    window.open("https://www.behance.net/raynecarne84da","_blank");
  }

}
